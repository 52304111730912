































































































































import { promiseHelper } from '@/helpers/promise-helper'
import { SellerBoardItemViewModel } from '../viewmodels/seller-board-viewmodel'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import PoolState from './pool-state.vue'
@Observer
@Component({ components: { PoolState } })
export default class FundTokenDialog extends Vue {
  @Prop({ required: true, default: null }) vm!: SellerBoardItemViewModel
  tokenAddress: string | undefined = ''
  dialog = false
  resolve?: (any) => void
  async open() {
    if (this.vm.checkContract()) {
      this.tokenAddress = this.vm.poolStore.pool.tokenAddress || ''
      this.dialog = true
      if (!this.vm.approveLoading && !this.vm.fundLoading) {
        await this.vm.fetchFundTokens()
      }
      await promiseHelper.delay(100)
      return new Promise((r) => (this.resolve = r))
    }
  }

  async fundTokens() {
    const res = await this.vm.fundTokens()
    if (res) this.cancel()
  }

  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
  }
}
