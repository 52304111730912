



































import { promiseHelper } from '@/helpers/promise-helper'
import { SellerBoardItemViewModel } from '../viewmodels/seller-board-viewmodel'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import PoolState from './pool-state.vue'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
@Observer
@Component({ components: { PoolState } })
export default class FundTokenDialog extends Vue {
  @Prop({ required: true, default: null }) vm!: SellerBoardItemViewModel
  dialog = false
  resolve?: (any) => void
  async open() {
    if (this.vm.checkContract()) {
      try {
        await this.vm.fetchWithdrawUnsold()
        this.dialog = true
        await promiseHelper.delay(100)
        return new Promise((r) => (this.resolve = r))
      } catch (e: any) {
        snackController.error(e.message || e.msg)
      }
    }
  }
  async withdrawUnsold() {
    await this.vm.withdrawUnsoldTokens()
    this.dialog = false
  }

  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
  }
}
