import { ChainType } from '@/blockchainHandlers'
import { SolanaIdoContract } from '@/blockchainHandlers/ido-contract-solana'
import { SolidityIdoContract } from '@/blockchainHandlers/ido-contract-solidity'
import { alertController } from '@/components/alert/alert-controller'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { Zero } from '@/constants'
import { reactionWithPrev } from '@/helpers/mobx.helper'
import { promiseHelper } from '@/helpers/promise-helper'
import { FixedPoolModel } from '@/models/fixed-pool-model'
import { apiService } from '@/services/api-service'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { PublicKey } from '@solana/web3.js'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import web3 from 'web3'
import { bigNumberHelper } from '../../../helpers/bignumber-helper'
import { PoolStore } from '../../ido/stores/pool-store'
import { poolsStore } from '../stores/pools-store'
export class SellerBoardItemViewModel {
  destroy(): void {
    //
  }
  @observable isApproved = false
  @observable actionLoading = false
  @observable pauseLoading = false
  @observable claimLoading = false
  @observable withdrawUnsoldLoading = false
  @observable fundLoading = false
  @observable approveLoading = false
  @observable approveChecking = false
  @observable fundAmountInput = ''
  @observable userIdoTokenBalance = Zero
  @observable claimError = ''
  @observable tradeTokenBalance = FixedNumber.from('0')
  @observable withdrawUnsoldError = ''
  @observable isPaused = false

  constructor(public poolStore: PoolStore) {
    this.isPaused = this.poolStore.isPausedContract
  }

  @asyncAction *approved() {
    try {
      this.isApproved = false
      this.approveChecking = true
      if (!this.poolStore?.contract) throw 'Contract is undefined'
      this.userIdoTokenBalance = yield this.poolStore.contract.getUserIdoTokenAmount(walletStore.account)
      if (this.chain !== 'sol') {
        const contract = this.poolStore.contract as SolidityIdoContract
        if (!contract) throw 'Contract is undefined'
        const tokenContract = contract.erc20Contract
        this.isApproved = yield tokenContract.isApproved(walletStore.account, this.poolStore.pool.address)
      } else {
        this.isApproved = true
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.approveChecking = false
    }
  }
  @action changeFundAmount(fundAmount) {
    this.fundAmountInput = fundAmount
  }

  @asyncAction *fetchIdoTokenBalance() {
    const contract = this.poolStore.contract
    if (contract) {
      this.userIdoTokenBalance = yield contract.getUserIdoTokenAmount(walletStore.account)
    }
  }

  @computed get amountError() {
    if (this.fundTokenCompleted) return 'Fund completely'
    if (bigNumberHelper.gt(this.validFundAmountInput, this.poolStore.fundableToken)) return 'Over fundable'
    if (bigNumberHelper.gt(this.validFundAmountInput, this.userIdoTokenBalance)) return 'Insufficient balance'
    return ''
  }
  @computed get fundableToken() {
    return this.poolStore.fundableToken.toString()
  }

  @computed get totalFundAmount() {
    return this.validFundAmountInput.addUnsafe(this.poolStore.tokensFund).toString()
  }

  @computed get tokensFund() {
    return this.poolStore.tokensFund.toString()
  }

  @computed get invalidFundAmountInputError() {
    return bigNumberHelper.lte(this.validFundAmountInput, Zero)
  }

  @computed get validClaim() {
    return !this.tradeTokenBalance.isZero()
  }

  @computed get validWithdrawUnsold() {
    return bigNumberHelper.gt(FixedNumber.from(this.totalTokenUnsold), Zero)
  }

  @computed get validFundAmountInput() {
    try {
      return FixedNumber.from(this.fundAmountInput)
    } catch {
      return FixedNumber.from('0')
    }
  }

  @computed get tradeTokenSellerTax() {
    return this.tradeTokenBalance.mulUnsafe(this.sellerTax).divUnsafe(FixedNumber.from('100')).toString()
  }
  @computed get fundTokenCompleted() {
    return bigNumberHelper.lte(this.poolStore.fundableToken, FixedNumber.from('0'))
  }

  @computed get chain() {
    return this.poolStore.pool.chain as ChainType
  }

  @computed get chainId() {
    return this.poolStore.pool.chainId
  }

  @computed get isCorrectSmartChain() {
    return walletStore.chainType === this.chain && walletStore.chainId === this.chainId
  }

  @computed get addressError() {
    if (this.chain !== 'sol')
      return this.idoTokenAddress && web3.utils.isAddress(this.idoTokenAddress) ? '' : 'Invalid address'
    try {
      new PublicKey(this.idoTokenAddress)
      return ''
    } catch (error) {
      return 'Invalid address'
    }
  }

  @computed get userTax() {
    return this.poolStore.userTax || 0
  }

  @computed get tradeToken() {
    return this.poolStore.tradeToken || ''
  }

  @computed get idoTokenName() {
    return this.poolStore.tokenName || ''
  }

  @computed get totalToken() {
    return this.poolStore.totalTokens || 0
  }

  @computed get totalTokenUnsold() {
    return bigNumberHelper.gte(this.poolStore.totalTokens, this.poolStore.purchasedTokens)
      ? this.poolStore.totalTokens.subUnsafe(this.poolStore.purchasedTokens).toString()
      : '0'
  }

  @computed get idoTokenAddress() {
    return this.poolStore.pool.tokenAddress || ''
  }

  @computed get sellerTax() {
    return this.poolStore.sellerTax
  }

  @asyncAction *fetchFundTokens() {
    this.fundAmountInput = ''
    this.isApproved = false
    if (this.idoTokenAddress && (this.chain === 'sol' || web3.utils.isAddress(this.idoTokenAddress))) {
      yield this.approved()
    }
  }

  @action.bound maxTokensFund() {
    this.fundAmountInput = this.fundableToken
  }

  @action checkContract() {
    const contract = this.poolStore.contract
    if (!contract) {
      snackController.error('Contract not found')
      return false
    }
    return true
  }

  @asyncAction *fetchClaim() {
    this.claimError = ''
    const contract = this.poolStore.contract
    if (contract) this.tradeTokenBalance = yield contract.getPoolTradeTokenAmount()
  }

  @asyncAction *fetchWithdrawUnsold() {
    this.withdrawUnsoldError = ''
    if (this.poolStore?.poolState && this.poolStore.poolState.ended) {
      const unsoldTokenReedemed = yield this.poolStore.unsoldTokensReedemed
      if (unsoldTokenReedemed) {
        this.withdrawUnsoldError = 'Withdraw completely'
      } else if (bigNumberHelper.gt(this.poolStore.totalTokens, this.poolStore.tokensFund)) {
        this.withdrawUnsoldError = 'You have to fund enough tokens first to be able withdraw unsold'
      }
    } else {
      const endDate = moment(this.poolStore.pool.endDate).format('DD/MM/YYYY HH:mm')
      this.withdrawUnsoldError = `Effective from ${endDate}`
    }
  }

  @asyncAction *claimMoney() {
    const contract = this.poolStore?.contract
    try {
      if (!contract) throw 'Contract is undefined'
      if (this.poolStore?.chain === 'sol') {
        const tradeToken = (contract as SolanaIdoContract).tradeTokenProgram
        if (!(yield tradeToken.hasAssociatedTokenAddress(walletStore.account))) {
          if (yield alertController.confirm('Add SPL Token', 'Need to add Token to your wallet first', 'Add')) {
            yield tradeToken.createAssociatedTokenAddress(walletStore.account)
          } else {
            return
          }
        }
      }
      this.claimLoading = true
      yield contract.withdrawFunds(walletStore.account)
      snackController.success('Claim monney successfully!')
      this.poolStore.fetchPoolInfo()
    } catch (e: any) {
      snackController.error(e.message || e.msg)
    } finally {
      this.claimLoading = false
    }
  }

  @asyncAction *withdrawUnsoldTokens() {
    const contract = this.poolStore.contract
    try {
      if (!contract) throw 'Contract is undefined'
      if (this.poolStore?.chain === 'sol') {
        const idoToken = (contract as SolanaIdoContract).idoTokenProgram
        if (!(yield idoToken.hasAssociatedTokenAddress(walletStore.account))) {
          if (yield alertController.confirm('Add SPL Token', 'Need to add Token to your wallet first', 'Add')) {
            yield idoToken.createAssociatedTokenAddress(walletStore.account)
          } else {
            return
          }
        }
      }
      this.withdrawUnsoldLoading = true
      snackController.success('Withdraw unsold tokens successfully!')
      this.poolStore.fetchPoolInfo()
    } catch (e: any) {
      snackController.error(e.message || e.msg)
    } finally {
      this.withdrawUnsoldLoading = false
    }
  }

  @asyncAction *approve() {
    try {
      this.approveLoading = true
      if (walletStore.chainType !== 'sol') {
        const tokenContract = (this.poolStore.contract as SolidityIdoContract).erc20Contract
        if (walletStore.walletConnected && walletStore.chainId === this.poolStore.chainId) {
          if (!walletStore.web3) throw 'web3 is undefined'
          tokenContract.injectProvider(walletStore.web3)
        }
        yield tokenContract.approve(walletStore.account, this.poolStore.pool.address)
        this.isApproved = true
      }
    } catch (e: any) {
      snackController.error(e.message || e.msg)
    } finally {
      this.approveLoading = false
    }
  }

  @asyncAction *fundTokens() {
    const contract = this.poolStore?.contract
    if (contract) {
      try {
        this.fundLoading = true
        yield contract.fund(this.fundAmountInput, walletStore.account)
        yield this.fetchIdoTokenBalance()
        this.poolStore.fetchPoolInfo()
        this.fundAmountInput = '0'
        snackController.success('Fund tokens successfully!')
        this.fundLoading = false
        return true
      } catch (e: any) {
        snackController.error(e.message || e.msg)
      } finally {
        this.fundLoading = false
      }
    } else {
      snackController.error('Contract is undefined')
    }
    return false
  }

  @asyncAction *pauseContract() {
    const contract = this.poolStore.contract
    if (contract) {
      try {
        loadingController.increaseRequest()
        const pausedState = yield contract.isPaused()
        loadingController.decreaseRequest()
        const res = yield alertController.confirm(
          pausedState ? 'Unpause Project ?' : 'Pause Project ?',
          pausedState
            ? 'You want to unpause project?  Investor can continue to invest.'
            : 'Investors can not invest in your project untill you relaunch. Are you sure?',
          pausedState ? 'Unpause' : 'Pause',
          'Cancel'
        )
        this.pauseLoading = true
        if (res) {
          if (pausedState) {
            yield contract.unpauseContract(walletStore.account)
            snackController.success('Unpause project successfully!')
          } else {
            yield contract.pauseContract(walletStore.account)
            snackController.success('Pause project successfully!')
          }
          this.isPaused = !this.isPaused
          this.poolStore.fetchPoolInfo()
        }
      } catch (e: any) {
        snackController.error(e.message || e.msg)
      } finally {
        this.pauseLoading = false
        loadingController.decreaseRequest()
      }
      return false
    } else snackController.error('Contract is undefined')
  }
}

export class SellerBoardViewModel {
  private _diposers: IReactionDisposer[] = []

  constructor() {
    this._diposers = [
      reaction(
        () => walletStore.account,
        () => this.fetchPools(),
        { fireImmediately: true }
      ),
      reactionWithPrev(() => this.sellerBoard, this._handlePoolsChanged),
    ]
  }

  _handlePoolsChanged(newPools: SellerBoardItemViewModel[], oldPools?: SellerBoardItemViewModel[]) {
    oldPools?.forEach((p) => p.destroy())
  }

  @observable totalCount = 0
  @observable sellerBoard: SellerBoardItemViewModel[] = []
  @observable closedFilter = false
  @observable runningFilter = false

  @action.bound toggleClosedFilter() {
    this.closedFilter = !this.closedFilter
  }
  @action.bound toggleRunningFilter() {
    this.runningFilter = !this.runningFilter
  }

  @asyncAction *fetchPools() {
    let res: any = []
    try {
      if (walletStore.account) {
        loadingController.increaseRequest()
        let results = []
        const params = {
          _sort: 'index:DESC',
          ownerAddress: walletStore.account,
        }

        results = yield Promise.all([
          apiService.fixedPool.find(params),
          apiService.fixedPool.count({ ownerAddress: walletStore.account }),
        ])

        res = (results[0] as FixedPoolModel[]).map((p) => poolsStore.getStore(p))
        this.totalCount = results[1]
      }
    } catch (e: any) {
      snackController.error(e.message || e.msg)
    } finally {
      this.sellerBoard = res.map((p) => new SellerBoardItemViewModel(p))
      loadingController.decreaseRequest()
    }
  }

  @computed get filteredSellerBoard() {
    const noFilter = !this.closedFilter && !this.runningFilter
    return noFilter || (this.runningFilter && this.closedFilter)
      ? this.sellerBoard.filter((p) => p.poolStore.poolState && p.poolStore.poolState.started)
      : this.runningFilter
      ? this.sellerBoard.filter(
          (p) => p.poolStore.poolState && p.poolStore.poolState.started && !p.poolStore.poolState.ended
        )
      : this.sellerBoard.filter((p) => p.poolStore.poolState && p.poolStore.poolState.ended)
  }

  async destroy() {
    this.sellerBoard = []
    await promiseHelper.delay(1)
    this._diposers.forEach((d) => d())
  }
}
