var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{
      'card-border': !_vm.disableBorder,
      'no-border ': _vm.disableBorder,
      'big-primary-border': hover && !_vm.disableHover,
      darker: _vm.darker,
      shadow: !_vm.disableShadow,
      'blend-overlay': _vm.blendOverlay,
      'no-materilal-card': !_vm.hasMaterialCardBg,
      root: _vm.hasMaterialCardBg,
      'blur-md': _vm.blurLevel === 'blur-md',
      'blur-sm': _vm.blurLevel === 'blur-sm',
    },attrs:{"outlined":"","max-width":_vm.maxWidth,"v-bind":_vm.$attrs,"rounded":_vm.rounded}},[_vm._t("default")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }