




























































































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardHover from '@/components/card-hover.vue'
import PoolState from './pool-state.vue'
import { SellerBoardItemViewModel } from '../viewmodels/seller-board-viewmodel'
import SellerBoardFundDialog from './seller-board-fund-dialog.vue'
import SellerBoardClaimDialog from './seller-board-claim-dialog.vue'
import SellerBoardWithdrawUnsoldDialog from './seller-board-withdraw-unsold-dialog.vue'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: { PoolState, CardHover, SellerBoardFundDialog, SellerBoardClaimDialog, SellerBoardWithdrawUnsoldDialog },
})
export default class SellerBoardItem extends Vue {
  @Prop({ required: true, default: null }) vm!: SellerBoardItemViewModel
  walletStore = walletStore
  async fundTokens() {
    await (this.$refs.sellerBoardFundDialog as any).open()
  }
  async claim() {
    await (this.$refs.sellerBoardClaimDialog as any).open()
  }
  async withdrawUnsold() {
    await (this.$refs.sellerBoardWithdrawUnsoldDialog as any).open()
  }
}
