

























import { Observer } from 'mobx-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Observer
@Component
export default class CardHover extends Vue {
  @Prop({ default: false }) disableHover
  @Prop({ default: false }) disableShadow
  @Prop({ default: false }) disableBorder
  @Prop({ default: false }) darker
  @Prop({ default: null }) maxWidth
  @Prop({ default: false }) rounded
  @Prop({ default: false }) blendOverlay
  @Prop({ default: false }) hasMaterialCardBg
  @Prop({ default: 'blur-md' }) blurLevel
}
