<template>
  <div class="fill-height d-flex flex-column">
    <div class="background-1 flex-fill">
      <v-container class="dashboard-container py-12">
        <v-row v-if="vm.sellerBoard.length !== 0">
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <div class="title-text">Your created projects</div>
              <v-btn
                outlined
                rounded
                class="font-weight-bold"
                :class="$vuetify.theme.dark ? 'primary--text' : 'new-project'"
                @click="applyProject"
              >
                <v-icon>mdi-plus</v-icon> New Project
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <v-btn
                rounded
                small
                class="text-body-2 mr-3"
                @click="vm.toggleRunningFilter()"
                :class="vm.runningFilter ? 'primary' : ''"
                >Running</v-btn
              >
              <v-btn
                rounded
                small
                class="text-body-2 mr-3"
                @click="vm.toggleClosedFilter()"
                :class="vm.closedFilter ? 'primary' : ''"
                >Closed</v-btn
              >
            </div>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" v-for="model in vm.filteredSellerBoard" :key="model.poolStore.pool.id">
                <seller-board-item :vm="model" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="d-flex justify-center">
            <card-hover
              :disable-hover="true"
              class="pb-12 seller-board-empty d-flex flex-column align-center"
              :class="$vuetify.breakpoint.smAndUp ? 'card-md' : ''"
            >
              <div class="logo-animation-card" :class="$vuetify.theme.dark ? 'card-bg-dark' : 'card-bg-light'"></div>
              <div class="mb-5 px-2 text-center text-body-1 font-weight-medium" style="max-width: 410px">
                Looks like you haven't listed any tokens yet. Click here to apply to get listed on Pollinate!
              </div>
              <div class="d-flex justify-center mb-2">
                <v-btn class="text-none btn-text primary px-8" rounded @click="applyProject">Apply</v-btn>
              </div>
            </card-hover>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <company-footer />
  </div>
</template>
<script>
import SellerBoardItem from '../components/seller-board-item.vue'
import { SellerBoardViewModel } from '../viewmodels/seller-board-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Inject } from 'vue-property-decorator'
@Observer
@Component({
  components: {
    SellerBoardItem,
  },
})
export default class SellerBoard extends Vue {
  @Inject() providers
  @Provide() vm = new SellerBoardViewModel()
  destroyed() {
    this.vm.destroy()
  }

  applyProject() {
    window.open('https://forms.gle/ZtdVSorDNFyVNuem9', '_blank')
  }
}
</script>

<style scoped lang="scss">
.dashboard-container {
  max-width: 1100px !important;
  min-height: 400px;
}
.card-md {
  width: 586px;
}
.seller-board-empty {
  max-width: 800px;
}
.title-text {
  font-size: 20px;
  font-weight: 600;
}
.new-project {
  border: 1px solid #ff9e68;
  color: #ff9e68;
}
.logo-animation-card {
  width: 100%;
  height: 300px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.card-bg-dark {
  background-image: url('../../../assets/waggle-logo-animation.dark.svg');
}
.card-bg-light {
  background-image: url('../../../assets/waggle-logo-animation.light.svg');
}
</style>
